<template>
  <div class="about-page">
    <div class="container">
      <div class="section-about">
        <div class="about-1">
          <div class="b-left">
            <img src="@/assets/about/about1.jpg" />
          </div>
          <div class="b-right">
            <div class="block-description">
              <div>Hotel EQUO está ubicado estratégicamente en el corazón de Miraflores, sede de la mejor y más variada gastronomía, vida nocturna y fácil acceso a los principales puntos turísticos de la ciudad. A minutos del parque Kennedy, malecón y centro comercial Larcomar.</div>
            </div>
          </div>
        </div>
        <div class="about-2">
          <div class="b-left">
            <div class="b-top">
              <img src="@/assets/about/about2.jpg" />
            </div>
            <div class="b-bottom">
              <div class="block-description">
                <div>EQUO Hotel, cuenta con 29 habitaciones entre: Simples o matrimoniales, Dobles y Suites, diseñadas para brindar una estadía confortable a nuestros huéspedes.</div>
              </div>
            </div>
          </div>
          <div class="b-right">
            <div class="b-top">
              <div class="block-bg">
                <div>EQUO<br/>HOTEL</div>
              </div>
            </div>
            <div class="b-bottom">
              <img src="@/assets/about/about3.jpg" />
            </div>
          </div>
        </div>
        <div class="about-3">
          <div class="b-left">
            <img src="@/assets/about/about4.jpg" />
          </div>
          <div class="b-right">
            <div class="b-top">
              <img src="@/assets/about/about5.jpg" />
            </div>
            <div class="b-bottom">
              <div class="block-description">
                <div>Deja que Equo te enamore con sus ambientes modernos y elegantes y el encanto de un servicio exclusivo.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-4 about-new" style="border: 1px solid black;">
          <p style="font-size: 18px;"><strong>Horarios</strong></p>
          <div class="row">
            <div class="col-12 col-md-6">
              <ul>
                <li>Check in 15:00 horas</li>
                <li>Check out 12:00 horas</li>
                <li>Desayuno Buffet (De Lunes a Domingos de 6:30 a 10:00 horas)</li>
                <li>Restaurante (de 10:00 a 15:00 horas)</li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <p><strong>Recepción:</strong></p>
              <ul>
                <li>Atención 24 horas</li>
              </ul>
              <p><strong>Ventas y Reservas:</strong></p>
              <ul>
                <li>Lunes a Viernes de 8:30 a 18:30 hrs</li>
                <li>Sábados de 8:30 a 12:30 hrs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutPage',
  data: () => ({
    loading: false,
  })
}
</script>
